import { IoWarningSharp } from "react-icons/io5";

const NotFound = ({query}) => {
  return (
    <div className="flex flex-col justify-center items-center text-primary bg-amber-50 text-center mx-[10%] border-x-1 py-4">
        <IoWarningSharp className="w-7 h-7 text-yellow-500"/>
        <div className="text-xl font-medium mt-2 px-1">{`Không tìm thấy ${query}`}</div>
    </div>
  )
}

export default NotFound;